import type { Ref } from 'vue';
import { computed } from 'vue';
import type { TabsVariant, TabsStates } from '../../../types';
import { useThemable } from '../../../../utils/Themable/composables/useThemable';
import { simplifyTailwindClasses } from '../../../../../utils/simplifyTailwindClasses';
import type { ButtonVariant } from '@/components/Button/types';

export type UseTabClassesProps = {
  variant?: TabsVariant;
  color?: ButtonVariant;
  active: Ref<boolean>;
  disabled: Ref<boolean>;
};
export type TabClassMap<T extends string> = {
  disabled: Record<T, string>;
  default: Record<T, string>;
  active: Record<T, string>;
};

const defaultDefaultTabClasses =
  'cursor-pointer inline-block p-4  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300';
const defaultDisabledTabClasses =
  'inline-block p-4 text-gray-400 rounded-t-4 cursor-not-allowed dark:text-gray-500';
const defaultTabClasses: TabClassMap<ButtonVariant> = {
  default: {
    none: '',
    default: defaultDefaultTabClasses,
    blue: defaultDefaultTabClasses,
    alternative: defaultDefaultTabClasses,
    dark: defaultDefaultTabClasses,
    light: defaultDefaultTabClasses,
    green: defaultDefaultTabClasses,
    red: defaultDefaultTabClasses,
    yellow: defaultDefaultTabClasses,
    purple: defaultDefaultTabClasses,
    pink: defaultDefaultTabClasses,
    gray: defaultDefaultTabClasses,
    'blue-light': defaultDefaultTabClasses,
  },

  active: {
    none: '',
    default:
      'cursor-pointer inline-block p-4 text-base active dark:bg-gray-800 dark:text-base',
    blue: 'cursor-pointer inline-block p-4 text-blue-300 active dark:bg-gray-800 dark:text-blue-300',
    alternative:
      'cursor-pointer inline-block p-4 text-lime-400 active dark:bg-gray-800 dark:text-lime-400',
    dark: 'cursor-pointer inline-block p-4 bg-primary text-white active dark:bg-gray-800 dark:text-white',
    light:
      'cursor-pointer inline-block p-4 text-base active dark:bg-gray-800 dark:text-base',
    green:
      'cursor-pointer inline-block p-4 text-green-500 active dark:bg-gray-800 dark:text-green-500',
    red: 'cursor-pointer inline-block p-4 text-red-700 active dark:bg-gray-800 dark:text-red-700',
    yellow:
      'cursor-pointer inline-block p-4 text-orange-600 active dark:bg-gray-800 dark:text-orange-600',
    purple:
      'cursor-pointer inline-block p-4 text-purple-500 active dark:bg-gray-800 dark:text-purple-500',
    pink: 'cursor-pointer inline-block p-4 text-pink-500 active dark:bg-gray-800 dark:text-pink-500',
    gray: 'cursor-pointer inline-block p-4 bg-primary text-white active dark:bg-gray-800 dark:text-white',
    'blue-light':
      'cursor-pointer inline-block p-4 text-blue-300 active dark:bg-gray-800 dark:text-blue-300',
  },
  disabled: {
    none: '',
    default: defaultDisabledTabClasses,
    blue: defaultDisabledTabClasses,
    alternative: defaultDisabledTabClasses,
    dark: defaultDisabledTabClasses,
    light: defaultDisabledTabClasses,
    green: defaultDisabledTabClasses,
    red: defaultDisabledTabClasses,
    yellow: defaultDisabledTabClasses,
    purple: defaultDisabledTabClasses,
    pink: defaultDisabledTabClasses,
    gray: defaultDisabledTabClasses,
    'blue-light': defaultDefaultTabClasses,
  },
};
const underlineDefaultTabClasses =
  'cursor-pointer inline-block px-4 py-2 rounded-t-4 border-b-2 border-transparent hover:text-gray-600';
const underlineDisabledTabClasses =
  'inline-block px-4 py-2 text-gray-400 rounded-t-4 cursor-not-allowed dark:text-gray-500';
const underlineTabClasses: TabClassMap<ButtonVariant> = {
  default: {
    none: '',
    default: underlineDefaultTabClasses,
    blue: underlineDefaultTabClasses,
    alternative: underlineDefaultTabClasses,
    dark: underlineDefaultTabClasses,
    light: underlineDefaultTabClasses,
    green: underlineDefaultTabClasses,
    red: underlineDefaultTabClasses,
    yellow: underlineDefaultTabClasses,
    purple: underlineDefaultTabClasses,
    pink: underlineDefaultTabClasses,
    gray: underlineDefaultTabClasses,
    'blue-light': underlineDefaultTabClasses,
  },

  active: {
    none: '',
    default:
      'cursor-pointer inline-block px-4 py-2 rounded-t-4 border-b-2 border-default active dark:text-blue-500 dark:border-blue-500',
    blue: 'cursor-pointer inline-block px-4 py-2 text-primary rounded-t-4 border-b-2 border-primary active dark:text-blue-500 dark:border-blue-500',
    alternative:
      'cursor-pointer inline-block px-4 py-2 text-lime-400 rounded-t-4 border-b-2 border-lime-400 active dark:text-blue-500 dark:border-lime-400',
    dark: 'cursor-pointer inline-block px-4 py-2 bg-primary text-white rounded-t-4 border-b-2 border-primary active dark:text-blue-500 dark:border-blue-500',
    light:
      'cursor-pointer inline-block px-4 py-2 text-base rounded-t-4 border-b-2 border-base active dark:text-blue-500 dark:border-base',
    green:
      'cursor-pointer inline-block px-4 py-2 text-green-500 rounded-t-4 border-b-2 border-blue-600 active dark:text-green-500 dark:border-green-500',
    red: 'cursor-pointer inline-block px-4 py-2 text-red-700 rounded-t-4 border-b-2 border-red-700 active dark:text-red-700 dark:border-red-700',
    yellow:
      'cursor-pointer inline-block px-4 py-2 text-orange-600 rounded-t-4 border-b-2 border-orange-600 active dark:text-blue-500 dark:border-orange-600',
    purple:
      'cursor-pointer inline-block px-4 py-2 text-purple-500 rounded-t-4 border-b-2 border-purple-600 active dark:text-purple-500 dark:border-purple-500',
    pink: 'cursor-pointer inline-block px-4 py-2 text-pink-500 rounded-t-4 border-b-2 border-pink-600 active dark:text-pink-500 dark:border-pink-500',
    gray: 'cursor-pointer inline-block px-4 py-2 bg-primary text-white rounded-t-4 border-b-2 border-primary active dark:text-blue-500 dark:border-blue-500',
    'blue-light':
      'cursor-pointer inline-block px-4 py-2 text-primary rounded-t-4 border-b-2 border-primary active dark:text-blue-500 dark:border-blue-500',
  },
  disabled: {
    none: '',
    default: underlineDisabledTabClasses,
    blue: underlineDisabledTabClasses,
    alternative: underlineDisabledTabClasses,
    dark: underlineDisabledTabClasses,
    light: underlineDisabledTabClasses,
    green: underlineDisabledTabClasses,
    red: underlineDisabledTabClasses,
    yellow: underlineDisabledTabClasses,
    purple: underlineDisabledTabClasses,
    pink: underlineDisabledTabClasses,
    gray: underlineDisabledTabClasses,
    'blue-light': underlineDisabledTabClasses,
  },
};
const pillsDefaultTabClasses =
  'cursor-pointer inline-block py-3 px-4 rounded-4 hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white';
const pillsDisabledTabClasses =
  'inline-block p-4 text-gray-400 rounded-t-4 cursor-not-allowed dark:text-gray-500';
const pillsTabClasses: TabClassMap<ButtonVariant> = {
  default: {
    none: '',
    default: pillsDefaultTabClasses,
    blue: pillsDefaultTabClasses,
    alternative: pillsDefaultTabClasses,
    dark: pillsDefaultTabClasses,
    light: pillsDefaultTabClasses,
    green: pillsDefaultTabClasses,
    red: pillsDefaultTabClasses,
    yellow: pillsDefaultTabClasses,
    purple: pillsDefaultTabClasses,
    pink: pillsDefaultTabClasses,
    gray: pillsDefaultTabClasses,
    'blue-light': pillsDefaultTabClasses,
  },

  active: {
    none: '',
    default:
      'cursor-pointer inline-block py-3 px-4 text-white bg-blue-600 rounded-4 active',
    blue: 'cursor-pointer inline-block py-3 px-4 text-white bg-primary rounded-4 active',
    alternative:
      'cursor-pointer inline-block py-3 px-4 text-white bg-blue-600 rounded-4 active',
    dark: 'cursor-pointer inline-block py-3 px-4 bg-base text-white rounded-4 active',
    light:
      'cursor-pointer inline-block py-3 px-4 text-base bg-white rounded-4 active',
    green:
      'cursor-pointer inline-block py-3 px-4 text-white bg-green-500 rounded-4 active',
    red: 'cursor-pointer inline-block py-3 px-4 text-white bg-red-700 rounded-4 active',
    yellow:
      'cursor-pointer inline-block py-3 px-4 text-white bg-orange-600 rounded-4 active',
    purple:
      'cursor-pointer inline-block py-3 px-4 text-white bg-purple-600 rounded-4 active',
    pink: 'cursor-pointer inline-block py-3 px-4 text-white bg-pink-600 rounded-4 active',
    gray: 'cursor-pointer inline-block py-3 px-4 bg-base text-white rounded-4 active',
    'blue-light':
      'cursor-pointer inline-block py-3 px-4 text-white bg-primary rounded-4 active',
  },
  disabled: {
    none: '',
    default: pillsDisabledTabClasses,
    blue: pillsDisabledTabClasses,
    alternative: pillsDisabledTabClasses,
    dark: pillsDisabledTabClasses,
    light: pillsDisabledTabClasses,
    green: pillsDisabledTabClasses,
    red: pillsDisabledTabClasses,
    yellow: pillsDisabledTabClasses,
    purple: pillsDisabledTabClasses,
    pink: pillsDisabledTabClasses,
    gray: pillsDisabledTabClasses,
    'blue-light': pillsDisabledTabClasses,
  },
};

export function useTabClasses(props: UseTabClassesProps): {
  tabClasses: Ref<string>;
} {
  const theme = useThemable();
  const tabClasses = computed(() => {
    const isActiveTheme = theme.isActive.value;
    const color = props.color || 'default';
    const tabClassType: TabsStates = props.active.value
      ? 'active'
      : props.disabled.value
        ? 'disabled'
        : 'default';

    if (props.variant === 'default')
      return simplifyTailwindClasses(
        defaultTabClasses[tabClassType][color],
        isActiveTheme && tabClassType === 'active'
          ? theme.textClasses.value
          : '',
      );
    else if (props.variant === 'underline')
      return simplifyTailwindClasses(
        underlineTabClasses[tabClassType][color],
        isActiveTheme && tabClassType === 'active'
          ? [theme.borderClasses.value, theme.textClasses.value]
          : '',
      );
    else if (props.variant === 'pills')
      return simplifyTailwindClasses(
        pillsTabClasses[tabClassType][color],
        isActiveTheme && tabClassType === 'active'
          ? [theme.backgroundClasses.value, 'text-white']
          : '',
      );
    return '';
  });

  return {
    tabClasses,
  };
}
