import type { Ref } from 'vue';
import { computed } from 'vue';
import type { TabsVariant } from '../types';

export type UseTabsClassesProps = {
  variant: TabsVariant;
  lineHeight?: string;
};

export function useTabsClasses(props: UseTabsClassesProps): {
  ulClasses: Ref<string>;
  divClasses: Ref<string>;
} {
  const ulClasses = computed(() => {
    if (props.variant === 'default')
      return 'flex flex-wrap text-sm font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400';
    else if (props.variant === 'pills')
      return 'flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400';
    else if (props.variant === 'underline' && props.lineHeight)
      return 'flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 line-height-0';
    else if (props.variant === 'underline')
      return 'flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400';
    else if (props.variant === 'progress') return 'flex gap-4';
    return '';
  });

  const divClasses = computed(() => {
    if (props.variant === 'default')
      return 'relative border-b-2 border-gray-200';
    if (props.variant === 'underline')
      return 'relative text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700';
    return '';
  });

  return {
    ulClasses,
    divClasses,
  };
}
